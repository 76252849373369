import React from "react"
import Layout from "../components/Layout/Layout"
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Ilustrace2 from "../assets/custom-style/images/ilustrace-02.svg";
import Kontakt from "../components/Layout/Contact";
import Reference from "../components/Components/Reference";
import CarouselReference from "../components/Components/CarouselReference";

export default function Microsoft365() {
    return (
        <Layout>
            <div className="headerBluePic mb-5">
                <div className="headerBlue_content">
                    <div className="container">
                        <div className="row pt-4">
                            <div className="col-12 col-lg-6 pt-5">
                                <h1 className="heading-border-bottom-white">Microsoft 365</h1>
                                <h2 className="pt-1">Jsme váš <br />
                                    Microsoft partner</h2>
                                <p className="subHeadline pt-1">Zvažujete přechod Vaší firmy či organizace do cloud prostředí Microsoft 365, nebo jste se již rozhodli a hledáte partnera pro migraci a budoucí správu?</p>
                            </div>
                            <div className="col">
                                <div className="mx-auto pt-5">
                                    {/*<StaticImage layout={'fullWidth'} imgClassName={"it w-100"} src="../assets/custom-style/images/ilustrace-02.webp" />*/}
                                    <StaticImage src={"../assets/custom-style/images/ilustrace-02.svg"} className={"it w-100"} alt="ilustrace" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="subHeader pt-5 pb-5 text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3 mb-3"
                            data-sal="fade"
                            data-sal-delay="100"
                            data-sal-easing="ease"
                        >
                            <div className="card-icon">
                                <StaticImage imgClassName={"time-2"} alt={"Šetřete čas"} src="../assets/custom-style/images/icons/time-2.webp" />
                            </div>
                            <p className="pt-3">Šetřete čas díky <br className="d-md-inline d-none" />rychlému <br className="d-inline d-md-none" />přístupu <br className="d-md-inline d-none" />k informacím</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3"
                            data-sal="fade"
                            data-sal-delay="200"
                            data-sal-easing="ease"
                        >
                            <div className="card-icon">
                                <StaticImage imgClassName={"team-2"} alt={"Spolupracujte v týmů "} src="../assets/custom-style/images/icons/team-2.svg" />
                            </div>
                            <p className="pt-3">Spolupracujte <br className="d-md-inline d-none" />v týmu <br className="d-inline d-md-none" />efektivně <br className="d-md-inline d-none" />a pohodlně</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3"
                            data-sal="fade"
                            data-sal-delay="300"
                            data-sal-easing="ease"
                        >
                            <div className="card-icon">
                                <StaticImage imgClassName={"device"} alt={"Různé zařízení"} src="../assets/custom-style/images/icons/device.svg" />
                            </div>
                            <p className="pt-3">Využijte možnost <br className="d-md-inline d-none" />pracovat <br className="d-inline d-md-none" />z různých <br className="d-md-inline d-none" />typů zařízení</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mb-3"
                            data-sal="fade"
                            data-sal-delay="400"
                            data-sal-easing="ease"
                        >
                            <div className="card-icon">
                                <StaticImage imgClassName={"data"} alt={"Microsoft 365"} src="../assets/custom-style/images/icons/data.svg" />
                            </div>
                            <p className="pt-3">Zabezpečte svá <br className="d-md-inline d-none" />data <br className="d-inline d-md-none" />v rámci <br className="d-md-inline d-none" />Microsoft 365</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bgGrey pt-5 pb-5">
                <div className="container">
                    <h2 className="text-center">Microsoft 365 je lepší IT řešení</h2>
                    <p className="text-center pt-5">Je to asi smělé tvrzení, ale&nbsp;v&nbsp;prostředí malých a středně velkých firem Microsoft 365 Business <br className="d-none d-lg-inline d-xl-none"/>prakticky nemá konkurenci. <br className="d-none d-xl-inline" />Tvoří ucelené IT&nbsp;řešení pro&nbsp;práci s&nbsp;informacemi, sdílení, komunikaci <br className="d-none d-lg-inline d-xl-none"/>a&nbsp;spolupráci, je zabezpečné a&nbsp;funguje na&nbsp;různých zařízení.</p>
                    <div className="digiCards pt-5">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-3 mb-3">
                                <div className="card h-100" data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"time"} src="../assets/custom-style/images/icons/time.webp" alt={"čas"}/>
                                        </div>
                                        <h5 className="card-title pt-3">Šetřete svým časem</h5>
                                        <p className="card-text"><span className="d-none d-xl-inline">Chcete být produktivnější bez ohledu na to, jestli jste v kanceláři, na cestách, nebo na schůzce? </span>Microsoft 365 představuje moderní způsob používání známých nástrojů Microsoft Office na všech vašich zařízeních. <span className="d-none d-md-inline">Jinými slovy, umožňuje vám pracovat tak, jak chcete, a kdekoli jste.</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-3">
                                <div className="card h-100" data-sal="fade" data-sal-delay="400" data-sal-easing="ease">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"team"} src="../assets/custom-style/images/icons/team.webp" alt={"tým"}/>
                                        </div>
                                        <h5 className="card-title pt-3">Váš tým dokáže víc</h5>
                                        <p className="card-text d-none d-xl-block">Přáli byste si, aby spolupráce s kolegy a partnery, byla jednodušší, ať jsou kdekoliv? Díky Microsoft Teams můžete komunikovat a spolupracovat, jak se vám zlíbí. Chat, video call nebo prezentace, ve dvou či ve skupině. Organizování schůzek nebyla nikdy jednodušší.</p>
                                        <p className="card-text d-none d-md-block d-xl-none">Díky Microsoft Teams můžete komunikovat a spolupracovat, jak se vám zlíbí. Chat, video call nebo prezentace, ve dvou či ve skupině. Komunikace nikdy nebyla jednodušší.</p>
                                        <p className="card-text d-block d-md-none">Díky Microsoft Teams můžete komunikovat a spolupracovat, jak se vám zlíbí. Jednoduše a efektivně.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-3">
                                <div className="card h-100" data-sal="fade" data-sal-delay="500" data-sal-easing="ease">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"office"} src="../assets/custom-style/images/icons/office.webp" alt="office" />
                                        </div>
                                        <h5 className="card-title pt-3">Office vždy a všude</h5>
                                        <p className="card-text d-none d-xl-block">Už se vám někdy stalo, že jste v kanceláři vytvořili dokument a teď ho rychle potřebujete? S Office 365 máte přístup k vašim souborům na libovolném zařízení. Prostě vezmete první zařízení, co je po ruce, spustíte Word, Excel nebo PowerPoint a budete pokračovat, kde jste skončili.</p>
                                        <p className="card-text d-none d-md-block d-xl-none">S Office 365 máte přístup k vašim souborům na libovolném zařízení. Prostě vezmete první zařízení, co je po ruce a budete pokračovat, kde jste skončili.</p>
                                        <p className="card-text d-block d-md-none">S Office 365 máte přístup k vašim souborům na libovolném zařízení. Dokumenty tvoříte s tím, co máte po ruce.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-3">
                                <div className="card h-100" data-sal="fade" data-sal-delay="600" data-sal-easing="ease">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"lock"} src="../assets/custom-style/images/icons/lock.webp" alt="lock" />
                                        </div>
                                        <h5 className="card-title pt-3">Mějte data v bezpečí</h5>
                                        <p className="card-text d-none d-xl-block">Potřebujete chránit svá cenná data a osobní údaje? Kdo z vás má rád nevyžádanou poštu? Prostředí Microsoft 365 využívá moderní způsoby, jako je například druhý faktor ověření či pokročilý filtr nevyžádané pošty. Naši IT odborníci vám se zabezpečením rádi pomůžou.</p>
                                        <p className="card-text d-none d-md-block d-xl-none">Prostředí Microsoft 365 využívá moderní způsoby zabezpečení a ochrany informací. Například druhý faktor ověření, pokročilý filtr nevyžádané pošty a mnoho dalšího.</p>
                                        <p className="card-text d-block d-md-none">Prostředí Microsoft 365 využívá moderní způsoby zabezpečení a ochrany informací. Bezpečnost na špičkové úrovní.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <h3 className="endingTitle text-center mt-5 mb-4">Snadné ovládání pro uživatele a jistota pro majitele firem</h3> */}
                </div>
            </div>


            <div className="bgWhite pt-5 pb-5">
                <div className="container">
                    <h2 className="text-center">Migrace do prostředí Microsoft 365</h2>
                    <p className="text-center pt-5">Klíčem&nbsp;k&nbsp;úspěšnému přechodu (migraci)&nbsp;do&nbsp;prostředí Microsoft 365, je&nbsp;zkušený partner, který vaší firmu či&nbsp;organizaci celou migrací <br className="d-none d-xl-inline" />provede až&nbsp;do&nbsp;zdárného cíle. My například víme, že&nbsp;bez&nbsp;správné přípravy to nejde. A&nbsp;nejde to ani bez adopčního programu.</p>
                    <div className="digiCards">
                        <div className="row pt-5">
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="100" data-sal-easing="ease">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"analysis"} src="../assets/custom-style/images/icons/analysis.svg" alt={"Analýza"}/>
                                        </div>
                                        <h5 className="card-title pt-3">Analýza prostředí</h5>
                                        <p className="card-text">Je rozdíl, jestli jste malá rodinná firma, nebo procesně řízená společnost s více pobočkami. Nezbývá, než se s vámi seznámit a pochopit, jak pracujete.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"migration"} src="../assets/custom-style/images/icons/migration.svg" alt="Přechod" />
                                        </div>
                                        <h5 className="card-title pt-3">Plán přechodu</h5>
                                        <p className="card-text">Přechod do Microsoft 365 je projekt, který se bez správného plánu neobejde. Ale nebojte, když už jsme vás poznali, sestavení plánu pro nás bude hračka.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"service"} src="../assets/custom-style/images/icons/service.svg" alt={"Zřízení"} />
                                        </div>
                                        <h5 className="card-title pt-3">Zřízení služby</h5>
                                        <p className="card-text">Než budeme stavět služby, je třeba mít pořádné základy. Těmi základy jsou doména, licence, identity, CI, týmy, zabezpečení.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="400" data-sal-easing="ease">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"user"} src="../assets/custom-style/images/icons/user.svg" alt={"Uživatel"}/>
                                        </div>
                                        <h5 className="card-title pt-3">Příprava uživatelů</h5>
                                        <p className="card-text">Celé Microsoft 365 by vám bylo k ničemu bez uživatelů, proto by měli vědět, co se chystá. My je budeme informovat a vše jim názorně ukážeme.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="100" data-sal-easing="ease">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"mail"} src="../assets/custom-style/images/icons/mail.svg" alt="Pošta" />
                                        </div>
                                        <h5 className="card-title pt-3">Pošta a soubory</h5>
                                        <p className="card-text">Klíčovými službami jsou emaily a soubory. Ať už je teď máte kdekoliv, převedeme je tak, aby uživatel pokračoval v novém prostředí tam, kde předtím přestal.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"teams"} src="../assets/custom-style/images/icons/teams.svg" alt={"Teams"} />
                                        </div>
                                        <h5 className="card-title pt-3">Teams a další</h5>
                                        <p className="card-text">Teams dokáže propojit ostatní služby do jednoho super-nástroje. Dále vám ukážeme jak snadno vytvářet automatizované pracovní postupy pomoci Power Automate a představíme další důležité postupy.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"campaign"} src="../assets/custom-style/images/icons/campaign.svg" alt={"Kampaň"} />
                                        </div>
                                        <h5 className="card-title pt-3">Adopční kampaň</h5>
                                        <p className="card-text">Budete chtít připravit školení zaměstnanců? Budeme podporovat vaši zvídavost a pomáhat tam, kde bude třeba. Důležité je, aby uživatelé byli maximálně spokojeni s Microsoft 365.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3 mb-4" data-sal="fade" data-sal-delay="400" data-sal-easing="ease">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <div className="card-icon">
                                            <StaticImage imgClassName={"administration"} src="../assets/custom-style/images/icons/administration.svg" alt={"Správa"}/>
                                        </div>
                                        <h5 className="card-title pt-3">Následná správa</h5>
                                        <p className="card-text">Nasazením to nekončí, ale začíná. Naši specialisté vám budou k dispozici jako podpora uživatelů, budou vám pomáhat s rozvojem byznysu a starat se o bezpečnost.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <h3 className="endingTitle text-center mt-5 mb-4">Migracím se úspěšně věnujeme již řadu let</h3> */}


                    <CarouselReference />

                </div>
            </div>
            <Kontakt />
        </Layout>
    );
}